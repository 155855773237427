export const getHeaderLinks = (categories, appData, withFeedLink = true) =>
  categories.reduce(
    (acc, category) => {
      acc.push({
        key: category._id,
        path: `/categories/${category.slug}`,
        text: category.menuLabel,
        postAmount: category.postAmount,
      });
      return acc;
    },
    withFeedLink
      ? [
          {
            key: 'latest_posts',
            path: '/',
            text: appData.menuLabel,
            postAmount: categories.reduce((result, category) => (result += category.postAmount), 0),
          },
        ]
      : [],
  );
